var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.formTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "用户昵称", prop: "nickName" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.form.nickName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "nickName", $$v)
                  },
                  expression: "form.nickName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "所属机构", prop: "deptId" } },
            [
              _c("a-tree-select", {
                staticStyle: { width: "100%" },
                attrs: {
                  "dropdown-style": { maxHeight: "400px", overflow: "auto" },
                  "tree-data": _vm.deptOptions,
                  placeholder: "请选择",
                  replaceFields: _vm.replaceFields,
                  "tree-default-expand-all": "",
                },
                model: {
                  value: _vm.form.deptId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "deptId", $$v)
                  },
                  expression: "form.deptId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "手机号", prop: "phonenumber" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.form.phonenumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "phonenumber", $$v)
                  },
                  expression: "form.phonenumber",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "邮箱", prop: "email" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.form.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email",
                },
              }),
            ],
            1
          ),
          _vm.form.userId == undefined
            ? _c(
                "a-form-model-item",
                { attrs: { label: "用户名", prop: "userName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "userName", $$v)
                      },
                      expression: "form.userName",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.userId == undefined
            ? _c(
                "a-form-model-item",
                { attrs: { label: "密码", prop: "password" } },
                [
                  _c("a-input-password", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "性别", prop: "sex" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.sex,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "sex", $$v)
                    },
                    expression: "form.sex",
                  },
                },
                _vm._l(_vm.sexOptions, function (d, index) {
                  return _c(
                    "a-radio-button",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "状态", prop: "status" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.statusOptions, function (d, index) {
                  return _c(
                    "a-radio-button",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "岗位", prop: "postIds" } },
            [
              _c(
                "a-select",
                {
                  attrs: { mode: "multiple", placeholder: "请选择" },
                  model: {
                    value: _vm.form.postIds,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "postIds", $$v)
                    },
                    expression: "form.postIds",
                  },
                },
                _vm._l(_vm.postOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.postId } },
                    [_vm._v(" " + _vm._s(d.postName) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "角色", prop: "roleIds" } },
            [
              _c(
                "div",
                { staticClass: "role-name-wrap" },
                [
                  _vm._v(" （"),
                  _c("span", [_vm._v("当前拥有角色：")]),
                  _vm._l(_vm.form.roleNames, function (item, index) {
                    return _c("span", { key: item, staticClass: "_span" }, [
                      _vm._v(
                        _vm._s(
                          item +
                            (_vm.form.roleNames.length > 1 &&
                            index !== _vm.form.roleNames.length - 1
                              ? "、"
                              : "")
                        )
                      ),
                    ])
                  }),
                  _vm._v("） "),
                ],
                2
              ),
              _c(
                "a-select",
                {
                  attrs: { mode: "multiple", placeholder: "请选择" },
                  model: {
                    value: _vm.form.roleIds,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "roleIds", $$v)
                    },
                    expression: "form.roleIds",
                  },
                },
                _vm._l(_vm.roleOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.roleId } },
                    [_vm._v(" " + _vm._s(d.roleName) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入",
                  type: "textarea",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }