<template>
  <!-- 增加修改 -->
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :title="formTitle">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户昵称" prop="nickName">
        <a-input v-model="form.nickName" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="所属机构" prop="deptId">
        <a-tree-select
          v-model="form.deptId"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="deptOptions"
          placeholder="请选择"
          :replaceFields="replaceFields"
          tree-default-expand-all
        >
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="手机号" prop="phonenumber">
        <a-input v-model="form.phonenumber" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="邮箱" prop="email">
        <a-input v-model="form.email" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="用户名" prop="userName" v-if="form.userId == undefined">
        <a-input v-model="form.userName" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="密码" prop="password" v-if="form.userId == undefined">
        <a-input-password v-model="form.password" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="性别" prop="sex">
        <a-radio-group v-model="form.sex" button-style="solid">
          <a-radio-button v-for="(d, index) in sexOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="状态" prop="status">
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="岗位" prop="postIds">
        <a-select mode="multiple" v-model="form.postIds" placeholder="请选择">
          <a-select-option v-for="(d, index) in postOptions" :key="index" :value="d.postId">
            {{ d.postName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="角色" prop="roleIds">
        <div class="role-name-wrap">
          （<span>当前拥有角色：</span>
          <span class="_span" v-for="(item, index) in form.roleNames" :key="item">{{
            item + (form.roleNames.length > 1 && index !== form.roleNames.length - 1 ? '、' : '')
          }}</span>）
        </div>
        <a-select mode="multiple" v-model="form.roleIds" placeholder="请选择">
          <a-select-option v-for="(d, index) in roleOptions" :key="index" :value="d.roleId">
            {{ d.roleName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark">
        <a-input v-model="form.remark" placeholder="请输入" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getUser, addUser, updateUser } from '@/api/system/user'
import { TreeSelect } from 'ant-design-vue'

export default {
  name: 'CreateForm',
  props: {
    deptOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    },
    sexOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    ATreeSelect: TreeSelect
  },
  data() {
    return {
      replaceFields: { children: 'children', title: 'label', key: 'id', value: 'id' },
      // 岗位选项
      postOptions: [],
      // 角色选项
      roleOptions: [],
      // 默认密码
      initPassword: undefined,
      formTitle: '',
      // 表单参数
      form: {
        userId: undefined,
        deptId: 0,
        userName: undefined,
        nickName: undefined,
        password: undefined,
        phonenumber: undefined,
        email: undefined,
        sex: '3',
        status: '0',
        remark: undefined,
        roleIds: undefined,
        postIds: undefined
      },

      open: false,
      rules: {
        userName: [
          { required: true, message: '用户名不能为空', trigger: 'blur' },
          {
            pattern: /^[0-9A-Za-z]{3,30}$/,
            message: '用户名由字母或数字的组合，且为3到30位',
            trigger: 'blur'
          }
        ],
        nickName: [{ required: true, message: '用户昵称不能为空', trigger: 'blur' }],
        deptId: [{ required: true, message: '部门不能为空', trigger: 'change' }],
        password: [{ required: true, message: '密码不能为空', trigger: 'blur' }],
        email: [
          { required: true, message: '邮箱不能为空', trigger: 'blur' },
          {
            type: 'email',
            message: '请正确填写邮箱地址',
            trigger: ['blur', 'change']
          }
        ],
        phonenumber: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          {
            pattern: /^1\d{10}$/,
            message: '请正确填写手机号',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  filters: {},
  created() {
    this.getConfigKey('sys.user.initPassword').then(response => {
      this.initPassword = response.msg
    })
  },
  computed: {},
  watch: {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        userId: undefined,
        deptId: undefined,
        userName: undefined,
        nickName: undefined,
        password: undefined,
        phonenumber: undefined,
        email: undefined,
        sex: '3',
        status: '0',
        remark: undefined,
        postIds: undefined,
        roleIds: undefined
      }
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset()
      // this.$emit('select-tree')
      getUser().then(response => {
        this.postOptions = response.posts
        this.roleOptions = response.roles
        this.open = true
        this.formTitle = '新增用户'
        this.form.password = this.initPassword
      })
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      // this.$emit('select-tree')
      const userId = row ? row.userId : ids
      getUser(userId).then(response => {
        this.form = response.data
        this.postOptions = response.posts
        this.roleOptions = response.roles
        this.$set(this.form, 'postIds', response.postIds)
        this.$set(this.form, 'roleIds', response.roleIds)
        this.$set(this.form, 'roleNames', response.roleNames)
        this.open = true
        this.formTitle = '修改用户'
        this.form.password = ''
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      // this.form.postIds = this.postIds
      // this.form.roleIds = this.roleIds
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.userId !== undefined) {
            updateUser(this.form).then(response => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addUser(this.form).then(response => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.role-name-wrap {
  margin-top: -12px;
  font-size: 12px;
  color: #888;
  line-height: 30px;
}
</style>
